import { Footer } from "octalog-base";
import { Outlet } from "react-router-dom";

import { Header } from "./components/Header/Header";

interface LayoutProps {
  activeSection: string;
}

export function Layout({ activeSection }: LayoutProps) {
  return (
    <>
      <Header activeSection={activeSection} />
      <Outlet />
      <Footer />
    </>
  );
}
