import { rem } from "@mantine/core";

import type { LogoProps } from "./OctalogLogo";
import { useOctalogLogoColors } from "./use-octalog-logo-colors";

export function OctalogLogoText({ size, color, style, ...others }: LogoProps) {
  const colors = useOctalogLogoColors({ color });

  return (
    <svg
      {...others}
      style={{ width: rem(size), height: rem(size), ...style }}
      viewBox="0 0 325 190"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-471,-286)">
        <g>
          <path
            d="M476.7,455.9c0-9,6.9-15.6,16.3-15.6c9.4,0,16.3,6.6,16.3,15.6s-6.9,15.6-16.3,15.6C483.7,471.4,476.7,
                  464.8,476.7,455.9z M503.7,455.9c0-6.2-4.5-10.6-10.6-10.6c-6.1,0-10.6,4.5-10.6,10.6c0,6.2,4.5,10.6,10.6,
                  10.6C499.2,466.5,503.7,462.1,503.7,455.9z"
            fill={colors.color.toString()}
          />
          <path
            d="M529.6,455.9c0-9,6.9-15.6,16.2-15.6c4.9,0,9.2,1.8,12,5.1l-3.6,3.4c-2.2-2.4-4.9-3.5-8.1-3.5c-6.3,0-10.8,
                  4.4-10.8,10.6s4.5,10.6,10.8,10.6c3.2,0,5.9-1.2,8.1-3.6l3.6,3.5c-2.8,3.3-7.1,5.1-12,5.1C536.5,471.4,529.6,
                  464.9,529.6,455.9z"
            fill={colors.color.toString()}
          />
          <path d="M585.5,445.5h-10v-4.8h25.7v4.8h-10V471h-5.6V445.5z" fill={colors.color.toString()} />
          <path
            d="M641.1,464h-15.1l-3,7h-5.8l13.6-30.3h5.5L650,471h-5.9L641.1,464z M639.2,459.6l-5.7-13.2l-5.7,
                  13.2H639.2z"
            fill={colors.color.toString()}
          />
          <path d="M669.2,440.7h5.6v25.5h15.8v4.8h-21.5V440.7z" fill={colors.color.toString()} />
          <path
            d="M708.8,455.9c0-9,6.9-15.6,16.3-15.6c9.4,0,16.3,6.6,16.3,15.6s-6.9,15.6-16.3,15.6C715.7,471.4,708.8,
                  464.8,708.8,455.9z M735.8,455.9c0-6.2-4.5-10.6-10.6-10.6s-10.6,4.5-10.6,10.6c0,6.2,4.5,10.6,10.6,
                  10.6S735.8,462.1,735.8,455.9z"
            fill={colors.color.toString()}
          />
          <path
            d="M784.6,455.5h5.3v12.1c-3.2,2.6-7.6,3.9-11.9,3.9c-9.4,0-16.3-6.5-16.3-15.6c0-9,6.9-15.6,16.4-15.6c5.1,
                  0,9.3,1.7,12.2,4.9l-3.5,3.5c-2.4-2.4-5.1-3.5-8.4-3.5c-6.4,0-11,4.4-11,10.6c0,6.1,4.5,10.6,11,10.6c2.2,0,
                  4.3-0.4,6.2-1.6V455.5z"
            fill={colors.color.toString()}
          />
        </g>
        <g>
          <path
            d="M633.5,414.8l-43.6-18l-18.1-43.6l18-43.6l43.6-18.1l43.6,18l18.1,43.6l-18,43.6L633.5,414.8z M597.7,
                  389.1 l35.8,14.8l35.8-14.9l14.8-35.8l-14.9-35.8l-35.8-14.8l-35.8,14.9l-14.8,35.8L597.7,389.1z"
            fill={colors.color.toString()}
          />
        </g>
        <g>
          <polygon
            fill={colors.color.toString()}
            points="633.6,392.8 605.6,381.2 599.8,367.2 609.1,363.3 613.3,373.5 633.6,381.8 653.8,373.4 662.2,
                     353.2 653.8,332.9 633.5,324.6 623.4,328.8 619.5,319.4 633.5,313.6 661.6,325.2 673.2,353.2 661.6,
                     381.2"
          />
        </g>
        <g>
          <polygon
            fill={colors.color.toString()}
            points="633.6,371.7 620.5,366.3 615.1,353.2 620.5,340.1 633.6,334.7 646.7,340.1 649.4,346.6 640.1,
                     350.5 638.9,347.9 633.6,345.6 628.3,347.9 626.1,353.2 628.3,358.5 633.6,360.8 636.3,359.6 640.2,369"
          />
        </g>
      </g>
    </svg>
  );
}
